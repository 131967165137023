import revive_payload_client_Frd8gASYWa from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l19uMh4P89 from "/code/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_T5aD6w6QZh from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_kCUITUWXUN from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/code/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2OJXoS3RTe from "/code/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import switch_locale_path_ssr_7CGgCC9Jj0 from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DqO8wZLCYH from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_0pL4f4Rozx from "/code/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import ionicElements_client_2aalxWNTV0 from "/code/frontend/plugins/ionicElements.client.ts";
import fontawesome_client_YktGug6KNZ from "/code/frontend/plugins/fontawesome.client.ts";
import bootstrap_client_Uyd73t1ylZ from "/code/frontend/plugins/bootstrap.client.ts";
import maska_client_D8wFsCvpJf from "/code/frontend/plugins/maska.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_l19uMh4P89,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_2OJXoS3RTe,
  switch_locale_path_ssr_7CGgCC9Jj0,
  i18n_DqO8wZLCYH,
  plugin_0pL4f4Rozx,
  ionicElements_client_2aalxWNTV0,
  fontawesome_client_YktGug6KNZ,
  bootstrap_client_Uyd73t1ylZ,
  maska_client_D8wFsCvpJf
]