
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en",
  "sk",
  "pl",
  "hu",
  "de"
]

export const localeLoaders = {
  "cs": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }],
  "en": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }],
  "sk": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }],
  "pl": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }],
  "hu": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }],
  "de": [{ key: "../lang/fetchLang.ts", load: () => import("../lang/fetchLang.ts?hash=0a1fdf18&locale=cs" /* webpackChunkName: "locale__code_frontend_lang_fetchLang_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "label": "čeština",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    },
    {
      "code": "en",
      "label": "english",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    },
    {
      "code": "sk",
      "label": "slovenčina",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    },
    {
      "code": "pl",
      "label": "polski",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    },
    {
      "code": "hu",
      "label": "magyar",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    },
    {
      "code": "de",
      "label": "deutch",
      "files": [
        "/code/frontend/lang/fetchLang.ts"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "label": "čeština",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  },
  {
    "code": "en",
    "label": "english",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  },
  {
    "code": "sk",
    "label": "slovenčina",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  },
  {
    "code": "pl",
    "label": "polski",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  },
  {
    "code": "hu",
    "label": "magyar",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  },
  {
    "code": "de",
    "label": "deutch",
    "files": [
      {
        "path": "/code/frontend/lang/fetchLang.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
